"use client"

import { Loading } from "@components"
import { withStore } from "@components/StoreProvider"
import { getLang } from "@lib/reducers/langReducer"
import { useRouter } from "next/navigation"
import { useEffect, Suspense } from "react"
import { useSelector } from "react-redux"

const Root = () => {
    const lang = useSelector(state => getLang(state))
    const router = useRouter()

    useEffect(() => {
        router.replace(`/${lang}`)
    }, [lang,router])

    return <Loading />
}
export default withStore(Root);